// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: '/dutymap/api',
  gzkLayerName: 'atyrau:gzk_features_moved',
  gzkLayerNameOblast: 'atyrau:gzk_features_moved_oblast1',
  gsNameSpace: 'atyrau',
  gu_free_land: 'gu_free_land',
  investment_proposals: 'investment_proposals',
  monitoring_flooded_facilities: 'monitoring_flooded_facilities',
  roads: 'roads',
  builds: 'builds',
  eng_light_f: 'eng_light_f',
  blag_fontpol: 'blag_fontpol',
  blag_ost: 'blag_ost',
  eng_el_line: 'eng_el_line',
  eng_el_ktp: 'eng_el_ktp',
  disinsection: 'disinsection',
  user_work_layer: 'user_work_layer',
  phytosanitary: 'phytosanitary',
  teplosnabzheniye: 'teplosnabzheniye',
  vodosnabzheniye: 'vodosnabzheniye',
  gazosnabzheniye: 'gazosnabzheniye',
  restoration_works: 'restoration_works',
  accounting_technological_violations: 'accounting_technological_violations',
  osi: 'osi',
  elevator: 'elevator',
  modernization: 'modernization',
  blagoustroystvo_dvorov: 'blagoustroystvo_dvorov',
  ozeleneniye: 'ozeleneniye',
  kos_kanalizatsiya: 'kos_kanalizatsiya',
  kap_remont_voda: 'kap_remont_voda',
  obslizhivaniye_flagshtoka: 'obslizhivaniye_flagshtoka',
  tekushi_remont_fasadov: 'tekushi_remont_fasadov',
  tekushi_remont_karmashek: 'tekushi_remont_karmashek',
  tekushi_remont_det_sport_ploshchadok: 'tekushi_remont_det_sport_ploshchadok',
  tekushi_remont_park_skverov: 'tekushi_remont_park_skverov',
  zhil_fond: 'zhil_fond',
  address_plan: 'address_plan',
  streetsLyrName: 'bs_ts_rdc_street',
  auctionLyrName: 'auction_lands',
  contestLyrName: 'competition',
  gzk_features_moved: 'gzk_features_moved_archive',
  gzk_features_moved_oldvers_archive: 'gzk_features_moved_oldvers_archive',
  gzk_features_moved_oldvers2_archive: 'gzk_features_moved_oldvers2_archive',
  building: 'building',
  integration109: 'integration109',
  integration109_teplo: "integration109_teplo",
  zhkh: "point_object",
  polygonal_object: "inzh_el_ktp",
  linear_object: "linear_object",
  landscaping_point_object: "landscaping_point_object",
  eng_kan_line: "eng_kan_line",
  landscaping_polygonal_object: "landscaping_polygonal_object",
  people_linear_object: "people_linear_object",
  trans_linear_object: "trans_linear_object",
  trans_point_object: "trans_point_object",
  people_point_object: "people_point_object",
  people_linear_objects: "people_linear_object",
  people_polygonal_object: "people_polygonal_object",
  fountain: "fountain",
  bus_stop_pavilion: "bus_stop_pavilion",
  inzh_tel_line: "inzh_tel_line",
  inzh_light_line: "inzh_light_line",
  inzh_el_line: "inzh_el_line",
  traffic_lights: "traffic_lights",
  road_signs: "road_signs",
  cameras: "cameras",
  pointers: "pointers",
  inzh_el_ktp: "inzh_el_ktp",
  road: "road",
  ramps_and_tactile_tiles: "ramps_and_tactile_tiles",
  small_architectural_forms: "small_architectural_forms",
  flagpole: "flagpole",
  trees_atyrau: "trees_atyrau",
  sewage_pumping_stations: "sewage_pumping_stations",
  sewage_treatment_plants: "sewage_treatment_plants",
  landscaping_sites: "landscaping_sites",
  landscaping: "landscaping",
  art_irregularities: "art_irregularities",
  bridges: "bridges",
  markup: "markup",
  public_toilets: "public_toilets",
  dumpsters: "dumpsters",
  lift: "lift",
  water_station: "water_station",
  teplo_ist: "teplo_ist",
  teplo_station: "teplo_station",
  gas_control_points: "gas_control_points",
  public_areas: "public_areas",
  eng_water_line: "eng_water_line",
  eng_teplo_line: "eng_teplo_line",
  eng_gas_line: "eng_gas_line",
  water_monitoring: 'water_monitoring',
  implemented_projects: 'implemented_projects',
  local_producers: 'local_producers',
  testEnvironment: {
    flag: false
  },
  project: {
    urls: {
      default: {
        es: 'https://eatyrau.kz',
        bp: 'https://bp.eatyrau.kz',
        dm: 'http://dm.eatyrau.kazgisa.kz',
        om: 'https://geo.eatyrau.kz'
      },
      production: {
        es: 'https://eatyrau.kz',
        bp: 'https://bp.eatyrau.kz',
        dm: 'https://dm.eatyrau.kz',
        om: 'https://geo.eatyrau.kz'
      }
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
